<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>ID</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.id }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Mfr</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.mfr }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Part Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.partNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Part Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.partName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="3" class="py-0">
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="9">
                <span>{{ form.partDesc }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <!-- <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.currency"
                  disabled
                ></v-text-field> -->
                <span>{{ form.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Qty</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.qty }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>UnitPrice</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <!-- <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.unitPrice"
                  type="number"
                  @change="onChangePrice"
                  persistent-hint
                  :hint="formatPrice(form.unitPrice)"
                  disabled
                ></v-text-field> -->
                <span>{{ formatPrice(form.unitPrice) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Total</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ formatPrice(form.unitPrice * form.qty) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Warehouse</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.warehouse }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Location</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.location }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <!-- <v-btn color="primary" @click="save">
          Update
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-inventory",
  props: {
    dialog: Boolean,
    item: Object,
  },
  computed: {
    form() {
      const item = Object.assign({}, this.item);
      return item;
    },
  },
  methods: {
    save() {
      this.$emit("update", this.form);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    onChangePrice() {
      this.form.total = this.form.qty * this.form.unitPrice;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
