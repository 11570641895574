<template>
  <div>
    <v-data-table :headers="headers" :items="inventory" :search="search" @click:row="onClickRow">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                v-model="search"
                placeholder="Search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="success" v-on="on" small @click="exportExcel" class="m-4">
                    <v-icon>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export Excel</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.unitPriceIdr="{ item }">
        {{ formatPrice(item.unitPriceIdr) }}
      </template>
      <template v-slot:item.totalIdr="{ item }">
        {{ formatPrice(item.totalIdr) }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-inventory
      :dialog="dialog"
      :item="item"
      @update="update"
      @close="close"
    ></dialog-inventory>
  </div>
</template>

<script>
import fileDownload from "js-file-download";
import DialogInventory from "@/components/DialogInventory";

export default {
  name: "inventory",
  components: {
    DialogInventory,
  },
  data: () => ({
    search: "",
    headers: [
      {
        id: 1,
        text: "ID",
        value: "id",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Mfr",
        value: "mfr",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Part Number",
        value: "partNumber",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Part Name",
        value: "partName",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Description",
        value: "partDesc",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      // {
      //   id: 6,
      //   text: "Curr",
      //   value: "currency",
      //   divider: true,
      //   sortable: false,
      //   class: "light-blue lighten-2 font-weight-black",
      // },
      {
        id: 7,
        text: "Qty",
        value: "qty",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 8,
        text: "Unit Price RP",
        value: "unitPriceIdr",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "Total RP",
        value: "totalIdr",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 10,
        text: "Warehouse",
        value: "warehouse",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "Location",
        value: "location",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "Update Date",
        value: "updatedAt",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "Aging (Days)",
        value: "agingDays",
        divider: true,
        sortable: false,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    dialog: false,
    item: {},
    inventory: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getInventory")
        .then(response => {
          this.inventory = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async exportExcel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/exportInventory")
        .then(response => {
          fileDownload(response.data, "inventory.xlsx");
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.item = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    async update(item) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/updateInventory", item)
        .then(() => {
          const index = this.inventory.findIndex(x => x.id === item.id);
          if (index > -1) {
            this.inventory.splice(index, 1, item);
          }

          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
